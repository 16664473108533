@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tourney:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.about-container {
    min-height: 90vh;
    max-width: 1500px;
    margin: auto;
    padding: 0 24px;
}
.about-box {
    border: 2px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4.84656px 44.8307px rgba(255, 255, 255, 0.25);
    border-radius: 30px;
    padding: 3em 4em;
    font-family: Chakra Petch;
    min-height: 80vh;
    margin-top: 200px;
}
.about-box h1 {
    font-weight: 800;
    text-align: left;
}
.about-sff {
    margin: 35px 0 50px 0;
    max-width: 1200px;
    line-height: 1.6;
    font-size: 20px;
}
.about-rows {
    display: flex;
    justify-content: space-between;
}
.accordion {
    display: flex;
    flex-direction: column;
    /* height: 500px; */
    flex-wrap: wrap;
}

.item {
    background-color: rgba(34, 30, 30, 0.83);
    margin-bottom: 30px;
    padding: 10px 20px;
    width: 500px;
    border-radius: 10px;
    transition: all 0.5s ease-in;
}
.title {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.content {
    color: white;
    max-height: 0;
    overflow: hidden;
    transition: all 0.8s ease-in-out;
}
.content-show {
    height: auto;
    max-height: 9999px;
    margin: 20px 0;
    transition: all 0.8s ease-in-out;
}

/* responsive */

@media screen and (max-width: 1220px) {
    .item {
        width: 400px;
    }
    .title h2 {
        font-size: 22px;
    }
    .about-sff {
        font-size: 17px;
    }
    .about-container {
        padding: 0 12px;
    }
}
@media screen and (max-width: 990px) {
    .item {
        width: 350px;
    }
    .title h2 {
        font-size: 20px;
    }
    .about-sff {
        font-size: 15px;
    }
    .content-show {
        margin: 10px 0;
        font-size: 14px;
    }
}
@media screen and (max-width: 990px) {
    .item {
        width: 320px;
    }
    .about-box {
        margin-top: 100px;
    }
}
@media screen and (max-width: 840px) {
    .about-rows {
        flex-wrap: wrap;
    }
    .item {
        max-width: 450px;
    }
    .accordion {
        max-height: 435px;
    }
}
@media screen and (max-width: 550px) {
    .about-box {
        padding: 2em 3em;
    }
    .item {
        padding: 5px 15px;
        margin-bottom: 15px;
    }
    .title h2 {
        font-size: 17px;
    }
}
@media screen and (max-width: 460px) {
    .about-box {
        padding: 1em 2em;
    }
}
@media screen and (max-width: 420px) {
    .item {
        max-width: 250px;
    }
    .about-box {
        padding: 20px;
    }
    .title h2 {
        font-size: 14px;
    }
    .content-show {
        font-size: 12px;
    }
    .about-sff {
        margin: 20px 0 30px 0;
        font-size: 13px;
    }
    .about-box h1 {
        font-size: 30px;
    }
}
@media screen and (max-width: 310px) {
    .item {
        width: 200px;
    }
    .about-box h1 {
        font-size: 27px;
    }
}

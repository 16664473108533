.home-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0 24px;
  font-family: Tourney, sans-serif;
  text-align: center;
}
.bg{
  background-image: url("../images/herobg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-container h2{
  font-size: 64px;
  font-weight: 900;
}
.home-container h3{
  font-size: 48px;
  font-weight: 900;
}
.buttons{
  display: flex;
  margin-top: 24px;
}
.button {
	pointer-events: auto;
	cursor: pointer;
	background: #e7e7e7;
	border: none;
	padding: 10px 20px;
	margin: 0;
	font-family: Chakra Petch, sans-serif;
  font-size: 16px;
	position: relative;
	display: inline-block;
}

.button::before,
.button::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.button--mimas {
	letter-spacing: 0.05rem;
	border-radius: 0.5rem;
	overflow: hidden;
	color: #fff;
	background: #e7e7e7;
}

.button--mimas span {
	position: relative;
  font-size: 20px;
}

.button--mimas::before {
	content: '';
	background: #106AE0;
	width: 120%;
	left: -10%;
	transform: skew(30deg);
	transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.button--mimas:hover::before {
	transform: translate3d(100%,0,0);
}
.button--mimas:hover span {
  mix-blend-mode: difference;
}

.bflex{
  display: flex;
}

@media screen and (max-width: 820px) {
  h2{
    max-width: 500px;
  }
}
@media screen and (max-width: 550px) {
  .home-container h2{
    font-size: 36px;
    max-width: 250px;
  }
  .home-container h3{
    font-size: 28px;
  }
  .button--mimas span {
    font-size: 16px;
  }
  
}
@media screen and (max-width: 360px) {
  .buttons{
    display: flex;
    flex-direction: column;
  }
}
.timeline-container {
    /* min-height: 90vh;
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  justify-content: space-evenly;
  margin: auto;
  padding: 0 24px; */
    text-align: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timeline {
    height: 300px;
    width: 5px;
    background-color: #222222;
}

.stub1 {
    line-height: 300px;
    font-size: 24px;
    background-color: #202020;
}

.stub2 {
    height: 200px;
}

.circle {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0);
    border-radius: 50%;
    background-color: #5e5e5e;
}

.circleWrapper {
    position: relative;
}

/*
// Previous .message class for reference
.message {
    margin-left: 8px;
    position: absolute;
    top: 20%;
    left: 50%;
    min-width: 150px;
    font-weight: bold;
}
*/

.message {
    margin-top: 8px;
    margin-bottom: 8px;
    min-width: 150px;
    font-weight: bold;
}

.timeline-container h1 {
    margin-bottom: 30px;
    margin-top: 80px;
}

@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

/* .card {
    display: flex;
    flex-direction: column;
    width: 260px;
    max-width: 100%;
    background-color: rgba(214, 214, 214, 0.5);
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    justify-content: center;
    align-items: center;
    position: relative;
}

.card img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 20px ;
}
.SocialIcons{
    font-size: 16px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
.FooterLink {
    color: black;
    padding: 12px 12px 8px 12px;
    margin: 4px;
    border-radius: 50px;
    background: white;
    cursor: pointer;
}
.clubs {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 50px;
}
.links {
    align-content: center;
    justify-content: space-evenly;
    position: absolute;
}
.club-name {
    display: flex;
    justify-content: center;
    
    padding: 8px;
    color: gold;
    font-size: 21px;
    background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.5), rgba(255,255,255,0));

}

.footer-title {
    display: flex;
    justify-content: center;
    align-content: start;
    
}
.ftitle {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    font-size: 30px;
    padding: 4px;
    background-image: linear-gradient(to right, rgba(255,0,0,0.3), red , blue, rgba(0,0,255,0.3));
}
.main-logo{
    display: flex;
    justify-content: center; 
    margin-bottom: 80px;  
   
}
.main-logo img {
    width: 350px;
}*/

.FooterContainer{
    background-color: #060606;
    width: 100%;
    color: white;
    min-height: 50vh;
}

.FooterInnerContainer{
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    justify-content: space-between;
    margin: auto;
    padding: 0 24px;
}

.WsocLogo{
    max-width: 310px;
    display: flex;
    margin: 60px 0px;
    align-self: center;
    width: 100%;
}

.FooterBottom{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.FooterClubs{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin: auto;
    height: 150px;
}

.ClubName{
    font-size: 18px;
    font-weight: 600;
}

.SocialIcons{
    font-size: 16px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.FooterLink{
    color: black;
    background: white;
    padding: 8px 8px 4px 8px;
    border-radius: 50px;
    transition: 0.2s ease-in-out;
}
.FooterLink:hover{
    transform: scale(1.2);
    background: #41E5FF;
    color: black;
    transition: 0.2s ease-in-out;
    box-shadow: rgba(255, 255, 255, 0.25) 0px 50px 100px -20px,
    rgba(255, 255, 255, 0.3) 0px 30px 60px -30px,
    rgba(255, 255, 255, 0.35) 0px -2px 6px 0px inset;
}

@media screen and (max-width: 450px) {
    .WsocLogo{
        max-width: 150px;
    }
    .ClubName{
        font-size: 14px;
    }
}

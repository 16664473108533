@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

.container_sponsors{
    height: 200px;
    /* width: 350px; */
    overflow: hidden;
    text-align: center;
    margin: 35px;
    border-radius: 10px;
    /* box-shadow: rgba(255, 255, 255, 0.158) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}
.container_sponsors img{
    padding: .5rem;
    margin: auto;
    width: 80%;
    max-width: 320px;
    border-radius: 10px;
    height: auto;
}

.container_sponsors a{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
